import {
  Box,
  Grid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

function Dashboard() {
  // Add this mock data (replace with actual API data later)
  const mockRedeemData = [
    { date: '2024-03-01', total: 45 },
    { date: '2024-03-02', total: 38 },
    { date: '2024-03-03', total: 42 },
    { date: '2024-03-04', total: 65 },
    { date: '2024-03-05', total: 58 },
    { date: '2024-03-06', total: 52 },
    { date: '2024-03-07', total: 48 },
    { date: '2024-03-08', total: 75 },
    { date: '2024-03-09', total: 82 },
    { date: '2024-03-10', total: 44 },
    { date: '2024-03-11', total: 56 },
    { date: '2024-03-12', total: 63 },
    { date: '2024-03-13', total: 51 },
    { date: '2024-03-14', total: 49 },
    { date: '2024-03-15', total: 72 },
    { date: '2024-03-16', total: 68 },
    { date: '2024-03-17', total: 43 },
    { date: '2024-03-18', total: 55 },
    { date: '2024-03-19', total: 59 },
    { date: '2024-03-20', total: 67 },
    { date: '2024-03-21', total: 61 },
    { date: '2024-03-22', total: 53 },
    { date: '2024-03-23', total: 47 },
    { date: '2024-03-24', total: 41 },
    { date: '2024-03-25', total: 69 },
    { date: '2024-03-26', total: 73 },
    { date: '2024-03-27', total: 57 },
    { date: '2024-03-28', total: 64 },
    { date: '2024-03-29', total: 71 },
    { date: '2024-03-30', total: 54 },
    { date: '2024-03-31', total: 66 },
    // ... add more days
  ];

  const mockTopUsers = [
    { rank: 1, name: "John Doe", phoneNumber: '6281234567890', points: 1500 },
    { rank: 2, name: "Jane Smith", phoneNumber: '6281234567891', points: 1350 },
    { rank: 3, name: "Alice Johnson", phoneNumber: '6281234567892', points: 1200 },
    { rank: 4, name: "Bob Brown", phoneNumber: '6281234567893', points: 1100 },
    { rank: 5, name: "Charlie Davis", phoneNumber: '6281234567894', points: 1050 }
    // ... add more users
  ];

  return (
    <Box>
      <Heading mb={6}>Dashboard</Heading>

      {/* Stats Grid */}
      <Grid templateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap={6} mb={8}>
        <Stat p={4} shadow="md" border="1px" borderColor="gray.200" rounded="md">
          <StatLabel>Total Users</StatLabel>
          <StatNumber>100</StatNumber>
          <StatHelpText>All time</StatHelpText>
        </Stat>
        <Stat p={4} shadow="md" border="1px" borderColor="gray.200" rounded="md">
          <StatLabel>Unique Users</StatLabel>
          <StatNumber>85</StatNumber>
          <StatHelpText>Active this month</StatHelpText>
        </Stat>
        <Stat p={4} shadow="md" border="1px" borderColor="gray.200" rounded="md">
          <StatLabel>Total Redeems</StatLabel>
          <StatNumber>456</StatNumber>
          <StatHelpText>This month</StatHelpText>
        </Stat>
      </Grid>

      {/* Bar Chart */}
      <Box mb={8} p={4} shadow="md" border="1px" borderColor="gray.200" rounded="md">
        <Heading size="md" mb={4}>Redemptions by Day</Heading>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={mockRedeemData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="total" fill="#4299E1" />
          </BarChart>
        </ResponsiveContainer>
      </Box>

      {/* Top Users Table */}
      <Box p={4} shadow="md" border="1px" borderColor="gray.200" rounded="md">
        <Heading size="md" mb={4}>Top 5 Users by Points</Heading>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Rank</Th>
              <Th>Name</Th>
              <Th>Phone Number</Th>
              <Th isNumeric>Points</Th>
            </Tr>
          </Thead>
          <Tbody>
            {mockTopUsers.map((user) => (
              <Tr key={user.rank}>
                <Td>{user.rank}</Td>
                <Td>{user.name}</Td>
                <Td>{user.phoneNumber}</Td>
                <Td isNumeric>{user.points}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}

export default Dashboard; 